
import { Options, mixins } from "vue-class-component";
import Input from "@/components/Input/index.vue";
import { Form } from "vee-validate";
import ContactInfo from "@/mixins/ContactInfo";
import CommonPage from "@/mixins/CommonPage";

@Options({
  components: {
    Input,
    Form,
  },
})
export default class ConfirmEmail extends mixins(CommonPage, ContactInfo) {
  email = "";

  onNext(): void {
    if (!this.client?.hasFirstName || !this.client?.hasLastName || !this.client?.hasPhoneNumber) {
      this.$router.push({
        name: "ContactInformation",
        query: {
          id: this.id,
          status: this.status,
        },
      })
    } else {
      this.onAcceptInvite()
    }
  }
}
